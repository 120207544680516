<template>
  <comercial-tpl>
    <b-card
      title=""
      class=" mx-auto"
      
      align-v="center">
<!--
      <b-row align-h="end" class="text-right mb-2">
        <b-col cols="2">
          <router-link to="https://donaclara.com.br/api/v1/indique/ranking/sincronizar">
            <b-icon icon="arrow-repeat" font-scale="3.5"></b-icon><br>           
          </router-link>
        </b-col>
      </b-row>
-->

    <b-table-simple hover small responsive class="text-left">
     
    <b-thead head-variant="dark">

      <b-tr>
        <b-th colspan="3">RA</b-th>
        <b-th colspan="2">Nome</b-th>
        <b-th colspan="1">Pts</b-th>
      </b-tr>
    </b-thead>

    <b-tbody>
      <b-tr v-for="item in sql" :key="item.id">
       
        <b-td>{{item.codigo}}</b-td>
        <b-td></b-td>
        <b-td></b-td>
        <b-td>{{item.indicador}}</b-td>
        <b-td></b-td>
        
        <b-td>
          {{item.pts}}
        </b-td>
        
        
      </b-tr>

    </b-tbody>

    </b-table-simple>

      <div class="card-footer">
          
      </div>

    </b-card>
  </comercial-tpl>
</template>

<script>
// @ is an alias to /src

import ComercialTpl from '@/tpls/ComercialTpl.vue'

export default {
  name: 'Ranking',
  data() {
    return {
      sql:{},
        
    }
  },

  mounted() {
    // Fetch initial results
    this.getResults();
  },


  components: {
    ComercialTpl,
  },
/*
  created(){
    
    let usuarioAux = this.$store.getters.getUsuario;
    if(usuarioAux){
      this.usuario = this.$store.getters.getUsuario;
      this.$http.get(this.$urlApi+'newsletter/listar/',
      {"headers": {"authorization": "Bearer "+this.$store.getters.getToken}})
        .then(response => {
          //
          if(response.data.status){
            console.log(response);
            this.news = response.data.news.data;
            //this.pg = response.data.news;
          }
        })
          
          .catch(e => {
            console.log(e)
            alert("Erro! Tente novamente mais tarde!");
          })  

      }
    },
    */
  
  methods:{
    getResults(page = 1) {
    let usuarioAux = this.$store.getters.getUsuario;
    if(usuarioAux){
      this.usuario = this.$store.getters.getUsuario;
      this.$http.get(this.$urlApi+'indique/ranking')
      //{"headers": {"authorization": "Bearer "+this.$store.getters.getToken}})      
      
        .then(response => {
          if(response.data.status){
          console.log(response);
          this.sql = response.data.sql;
          }
        })
          .catch(e => {
            console.log(e)
            alert("Erro! Tente novamente mais tarde!");
          })          
      }   
    }

  },
}
  </script>

